@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 100;
  src: local(Montserrat Thin), local(Montserrat-Thin), url("montserrat-latin-100.1feefe40.woff2") format("woff2"), url("montserrat-latin-100.5a8b401f.woff") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-display: swap;
  font-weight: 100;
  src: local(Montserrat Thin italic), local(Montserrat-Thinitalic), url("montserrat-latin-100italic.02f74ddd.woff2") format("woff2"), url("montserrat-latin-100italic.946e72be.woff") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 200;
  src: local(Montserrat Extra Light), local(Montserrat-Extra Light), url("montserrat-latin-200.385e75e6.woff2") format("woff2"), url("montserrat-latin-200.2fe17fa3.woff") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-display: swap;
  font-weight: 200;
  src: local(Montserrat Extra Light italic), local(Montserrat-Extra Lightitalic), url("montserrat-latin-200italic.8aadbd55.woff2") format("woff2"), url("montserrat-latin-200italic.423c0582.woff") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: local(Montserrat Light), local(Montserrat-Light), url("montserrat-latin-300.3f1f40d2.woff2") format("woff2"), url("montserrat-latin-300.6559ca8a.woff") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-display: swap;
  font-weight: 300;
  src: local(Montserrat Light italic), local(Montserrat-Lightitalic), url("montserrat-latin-300italic.e0d05276.woff2") format("woff2"), url("montserrat-latin-300italic.611d0108.woff") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local(Montserrat Regular), local(Montserrat-Regular), url("montserrat-latin-400.a2250735.woff2") format("woff2"), url("montserrat-latin-400.7689b5bf.woff") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-display: swap;
  font-weight: 400;
  src: local(Montserrat Regular italic), local(Montserrat-Regularitalic), url("montserrat-latin-400italic.3c875ace.woff2") format("woff2"), url("montserrat-latin-400italic.21bf226a.woff") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: local(Montserrat Medium), local(Montserrat-Medium), url("montserrat-latin-500.7bd48b39.woff2") format("woff2"), url("montserrat-latin-500.4928638e.woff") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-display: swap;
  font-weight: 500;
  src: local(Montserrat Medium italic), local(Montserrat-Mediumitalic), url("montserrat-latin-500italic.8f674aa4.woff2") format("woff2"), url("montserrat-latin-500italic.c3365501.woff") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src: local(Montserrat SemiBold), local(Montserrat-SemiBold), url("montserrat-latin-600.01c4da89.woff2") format("woff2"), url("montserrat-latin-600.65e4909f.woff") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-display: swap;
  font-weight: 600;
  src: local(Montserrat SemiBold italic), local(Montserrat-SemiBolditalic), url("montserrat-latin-600italic.8e409435.woff2") format("woff2"), url("montserrat-latin-600italic.626326c2.woff") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: local(Montserrat Bold), local(Montserrat-Bold), url("montserrat-latin-700.8c9438c0.woff2") format("woff2"), url("montserrat-latin-700.fa8a261e.woff") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-display: swap;
  font-weight: 700;
  src: local(Montserrat Bold italic), local(Montserrat-Bolditalic), url("montserrat-latin-700italic.5c259f72.woff2") format("woff2"), url("montserrat-latin-700italic.cdb755c9.woff") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src: local(Montserrat ExtraBold), local(Montserrat-ExtraBold), url("montserrat-latin-800.3a5cf3e0.woff2") format("woff2"), url("montserrat-latin-800.be0764be.woff") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-display: swap;
  font-weight: 800;
  src: local(Montserrat ExtraBold italic), local(Montserrat-ExtraBolditalic), url("montserrat-latin-800italic.91eff186.woff2") format("woff2"), url("montserrat-latin-800italic.4d9557c8.woff") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-style: normal;
  font-display: swap;
  font-weight: 900;
  src: local(Montserrat Black), local(Montserrat-Black), url("montserrat-latin-900.da4f81b9.woff2") format("woff2"), url("montserrat-latin-900.d0d16d32.woff") format("woff");
}

@font-face {
  font-family: Montserrat;
  font-style: italic;
  font-display: swap;
  font-weight: 900;
  src: local(Montserrat Black italic), local(Montserrat-Blackitalic), url("montserrat-latin-900italic.19160922.woff2") format("woff2"), url("montserrat-latin-900italic.8ef2902a.woff") format("woff");
}
/*# sourceMappingURL=index.4ef8db67.css.map */
